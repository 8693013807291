// api/events.ts
import { supabase } from "../initSupabase";
import {
  isSameWeek,
  startOfWeek,
  endOfWeek,
  isMonday,
  subDays,
  addDays,
} from "date-fns";
import { getPagination } from "./utils";
import { EventTypeValue, EventData } from "../types/event";
import { MAX_FILE_SIZE } from "../constants/fileio";
import { DocumentResult } from "expo-document-picker";

interface QueryOptions {
  user_id?: string;
  page?: number;
  before?: Date;
  after?: Date;
  pageSize?: number;
}

export async function fetchEvents({
  query: { user_id, page = 0, before, after, pageSize = 20 } = {},
}: { query?: QueryOptions } = {}) {
  try {
    // Extract the page from the query or default to 1
    // use pagination util to calculate the from and to values
    const { from, to } = getPagination(page, pageSize);
    //
    let supabaseQuery = supabase.from("events").select("*", { count: "exact" });
    // Create a filter object based on the provided before and after values
    if (before) {
      supabaseQuery = supabaseQuery.lt("event_date", before.toISOString());
    }
    if (after) {
      supabaseQuery = supabaseQuery.gte("event_date", after.toISOString());
    }
    if (user_id) {
      supabaseQuery = supabaseQuery.eq("user_id", user_id);
    }
    supabaseQuery = supabaseQuery
      .range(from, to)
      .order("event_date", { ascending: false });
    // Fetch event and return the raw data
    const { data, count, error } = await supabaseQuery;

    if (error) {
      throw error;
    }

    return {
      data: data,
      count: count,
      page: +page,
    }; // Return the raw data
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
}

export async function fetchNextEvent(user_id?: string): Promise<any | null> {
  try {
    const currentDate = new Date();
    const today = new Date(currentDate.setHours(0, 0, 0, 0));

    // Fetch the first activity from today onwards where is_completed is false
    let supabaseQuery = supabase
      .from("events")
      .select("*")
      .eq("is_completed", false)
      .gte("event_date", today.toISOString())
      .order("event_date", { ascending: true })
      .limit(1);

    if (user_id) {
      supabaseQuery = supabaseQuery.eq("user_id", user_id);
    }

    const { data, error } = await supabaseQuery;

    if (error) {
      throw error;
    }

    return data.length > 0 ? { data: data[0], error } : { data: null, error };
  } catch (error) {
    console.error("Error fetching next event:", error);
    throw error;
  }
}

// Fetch event by id
export async function fetchEventById(id: string) {
  try {
    const { data, error } = await supabase
      .from("events")
      .select("*")
      .eq("id", id)
      .single();

    if (error) {
      throw error;
    }

    // Return the latest completed activity or null if none found
    return data;
  } catch (error) {
    console.error("Error fetching event by id:", error);
    throw error;
  }
}

// Insert new event
export async function createEvent(event: EventData) {
  try {
    const { data, error } = await supabase
      .from("events")
      .insert([event])
      .single();

    if (error) {
      throw error;
    }

    return { data, error };
  } catch (error) {
    console.error("Error creating events:", error);
    throw error;
  }
}

// Update event
export async function updateEvent(event: EventData) {
  try {
    // set event.updated_at: new Date().toISOString() before updating
    event.updated_at = new Date().toISOString();

    const { data, error } = await supabase
      .from("events")
      .update(event)
      .eq("id", event.id)
      .single();

    if (error) {
      throw error;
    }

    return { data, error };
  } catch (error) {
    console.error("Error updating event:", error);
    throw error;
  }
}

// Delete event
export async function deleteEvent(id: string) {
  try {
    const { data, error } = await supabase
      .from("events")
      .delete()
      .eq("id", id)
      .single();

    if (error) {
      throw error;
    }

    return { data, error };
  } catch (error) {
    console.error("Error deleting event:", error);
    throw error;
  }
}

export const uploadEventAttachment = async (
  file: DocumentResult,
  eventId: EventData["id"],
  userId: EventData["user_id"]
) => {
  try {
    if (file.type !== "success") {
      throw new Error("No file selected");
    }
    const fileName = file.name.split("/").pop(); // Extract file name
    console.log("fileUri", file.uri);
    console.log("fileName", fileName);
    const response = await fetch(file.uri);
    const blob = await response.blob();

    // Validate file size before uploading
    if (blob.size > MAX_FILE_SIZE) {
      throw new Error(
        `File size exceeds the limit of ${MAX_FILE_SIZE / (1024 * 1024)} MB.`
      );
    }

    // Define the file path: `event-id/file-name`
    const filePath = `${userId}/${eventId}/attachments/${fileName}`;

    // Upload the file to the specified path in the bucket
    const { data, error } = await supabase.storage
      .from("events") // Use your bucket name
      .upload(filePath, blob, {
        contentType: blob.type, // Set the MIME type
      });
    console.log("data", data);

    if (error) throw error;

    // Optionally get the public URL for the file
    const { data: publicUrlData } = supabase.storage
      .from("events")
      .getPublicUrl(filePath);

    return publicUrlData.publicUrl; // Return the file's public URL
  } catch (error) {
    console.error("File upload error:", error);
    throw error;
  }
};

export const fetchEventAttachments = async (
  eventId: EventData["id"],
  userId: EventData["user_id"]
) => {
  try {
    // List all files within the `event-id` folder
    const { data, error } = await supabase.storage
      .from("events")
      .list(`${userId}/${eventId}/attachments/`, { limit: 100 }); // Adjust `limit` as needed

    if (error) throw error;

    return data; // Returns an array of file metadata
  } catch (error) {
    console.error("Error fetching files:", error);
    throw error;
  }
};

export const deleteEventAttachment = async (
  eventId: EventData["id"],
  userId: EventData["user_id"],
  fileName: string
) => {
  try {
    const filePath = `${userId}/${eventId}/attachments/${fileName}`;

    const { error } = await supabase.storage.from("events").remove([filePath]);

    if (error) throw error;

    console.log("File deleted successfully:", filePath);
  } catch (error) {
    console.error("File deletion error:", error);
    throw error;
  }
};

// function to rename a file in the storage bucket
export const renameEventAttachment = async (
  eventId: EventData["id"],
  userId: EventData["user_id"],
  oldFileName: string,
  newFileName: string
) => {
  try {
    const oldFilePath = `${userId}/${eventId}/attachments/${oldFileName}`;
    const newFilePath = `${userId}/${eventId}/attachments/${newFileName}`;

    const { data, error } = await supabase.storage
      .from("events")
      .move(oldFilePath, newFilePath);

    if (error) throw error;

    console.log("File renamed successfully:", newFilePath);
  } catch (error) {
    console.error("File renaming error:", error);
    throw error;
  }
};

export const downloadEventAttachment = async (
  eventId: EventData["id"],
  userId: EventData["user_id"],
  fileName: string
) => {
  try {
    const filePath = `${userId}/${eventId}/attachments/${fileName}`;
    const { data, error } = await supabase.storage
      .from("events")
      .download(filePath);

    if (error) throw error;

    return data;
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};
