import {
  calculateLongTermWeeklyTotalsPerActivity,
  calculateWeeklyAndDailyTotalsPerActivity,
  calculateWeeklyTotalsPerActivityForDateRange,
} from "../../components/utils/activityUtils";
import { getStartOfWeeksForDateRange } from "../../components/utils/dateUtils";
import {
  ActivityData,
  ActivityTotalsPerActivity,
  WeeklyActivityTotalsPerActivityCollection,
} from "../../types/activity";
import {
  Schedule,
  ScheduledEvent,
  ScheduledItemTypes,
  ScheduledNote,
  ScheduledWorkout,
} from "../../types/schedule";

export const getWorkouts = (schedule: Schedule): ScheduledWorkout[] =>
  Object.values(schedule)
    .flat()
    .filter((item) => item.scheduledItemType === ScheduledItemTypes.WORKOUT);

export const getEvents = (schedule: Schedule): ScheduledEvent[] =>
  Object.values(schedule)
    .flat()
    .filter((item) => item.scheduledItemType === ScheduledItemTypes.EVENT);

export const getNotes = (schedule: Schedule): ScheduledNote[] =>
  Object.values(schedule)
    .flat()
    .filter((item) => item.scheduledItemType === ScheduledItemTypes.NOTE);

// Search for and return the latest completed workout in the schedule
export const findLatestCompletedWorkout = (
  schedule: Schedule
): ScheduledWorkout | null => {
  try {
    const workouts = getWorkouts(schedule);
    if (workouts.length === 0) {
      return null;
    }

    const completedWorkouts = workouts.filter(
      (workout) => workout.is_completed
    );
    if (completedWorkouts.length === 0) {
      return null;
    }

    completedWorkouts.sort((a, b) => {
      return (
        // Sort in descending order based on the started_at property
        new Date(b.started_at ?? 0).getTime() -
        new Date(a.started_at ?? 0).getTime()
      );
    });
    // Return the most recent completed activity
    return completedWorkouts[0];
  } catch (error) {
    console.error("Error finding latest completed workout:", error);
    throw error;
  }
};

// Search for and return the next uncompleted activity in the schedule
export function findNextWorkout(schedule: Schedule): ScheduledWorkout | null {
  try {
    const workouts = getWorkouts(schedule);
    if (workouts.length === 0) {
      return null;
    }

    const currentDate = new Date();
    const today = new Date(currentDate.setHours(0, 0, 0, 0));

    // Sort activities chronologically based on the workout_date property
    const sortedActivities = workouts.sort((a, b) => {
      return (
        new Date(a.workout_date ?? 0).getTime() -
        new Date(b.workout_date ?? 0).getTime()
      );
    });

    const nextActivity = sortedActivities.find((workout) => {
      // Return the first activity that is not completed and starts on or after today
      return (
        !workout.is_completed &&
        workout.workout_date &&
        new Date(workout.workout_date) >= today
      );
    });

    return nextActivity || null; // Return the next activity or null if not found
  } catch (error) {
    console.error("Error finding next activity:", error);
    throw error;
  }
}

export const findNextEvent = (schedule: Schedule): ScheduledEvent | null => {
  try {
    const currentDate = new Date();
    const today = new Date(currentDate.setHours(0, 0, 0, 0));

    const events = getEvents(schedule).filter(
      (event) =>
        !event.is_completed &&
        event.event_date &&
        new Date(event.event_date) >= today
    );

    if (events.length === 0) {
      return null;
    }

    events.sort((a, b) => {
      return (
        new Date(a.event_date ?? 0).getTime() -
        new Date(b.event_date ?? 0).getTime()
      );
    });

    return events[0];
  } catch (error) {
    console.error("Error finding next upcoming event:", error);
    throw error;
  }
};

// export function getOldestAndNewestDates(activities: ActivityData[]): {
//   oldestDate: Date;
//   newestDate: Date;
// } {
//   const dates = activities.map((activity) => new Date(activity.started_at));
//   const oldestDate = new Date(Math.min(...dates));
//   const newestDate = new Date(Math.max(...dates));
//   return { oldestDate, newestDate };
// }

export function getOldestAndNewestDates(schedule: Schedule): {
  oldestDate: Date;
  newestDate: Date;
} {
  const dateKeys = Object.keys(schedule).map((dateStr) => new Date(dateStr));

  const oldestDate = new Date(
    Math.min(...dateKeys.map((date) => date.getTime()))
  );
  const newestDate = new Date(
    Math.max(...dateKeys.map((date) => date.getTime()))
  );

  return { oldestDate, newestDate };
}

// function to calculate weekly totals for a date range
//  uses calculateLongTermWeeklyTotalsPerActivity to calculate weekly totals for each activity type
// TODO: reduce processing > just get workouts for date range only, then and pass to calculateLongTermWeeklyTotalsPerActivity
export function calculateWorkoutWeeklyTotalsPerActivityTypeForDateRange(
  schedule: Schedule,
  startDate: Date,
  endDate: Date,
  includeEmptyWeeks = true
): WeeklyActivityTotalsPerActivityCollection {
  const activities = getWorkouts(schedule);
  const weeklyTotals = calculateWeeklyTotalsPerActivityForDateRange(
    activities,
    startDate,
    endDate,
    includeEmptyWeeks
  );
  return weeklyTotals;
}

// function to calculate daily and weekly totals for a date range
// TODO: reduce processing > just get workouts for date range only, then and pass to calculateWeeklyAndDailyTotalsPerActivity
export function calculateWorkoutWeeklyAndDailyTotalsPerActivityForWeek(
  schedule: Schedule,
  targetDate: Date = new Date()
): ActivityTotalsPerActivity {
  const activities = getWorkouts(schedule);
  const totals = calculateWeeklyAndDailyTotalsPerActivity(
    activities,
    targetDate
  );
  return totals;
}

// function to find a workout in schedule by id
export function findWorkoutById(
  schedule: Schedule,
  workoutId: string
): ScheduledWorkout | null {
  try {
    const workouts = getWorkouts(schedule);
    const workout = workouts.find((workout) => workout.id === workoutId);
    return workout || null;
  } catch (error) {
    console.error("Error finding workout by id:", error);
    return null;
  }
}
