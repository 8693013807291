import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Dialog, Portal, Button, Text } from "react-native-paper";
import { FilePicker } from "../utils/FilePicker";
import { EventData } from "../../types/event";
import { DocumentResult } from "expo-document-picker";
import { uploadEventAttachment } from "../../api/events";
import { MAX_FILE_SIZE } from "../../constants/fileio";
import Loading from "../../screens/utils/Loading";
import { set } from "lodash";

interface UploadAttachmentDialogProps {
  eventId: EventData["id"];
  userId: EventData["user_id"];
  visible: boolean;
  onDismiss: () => void;
}

const UploadAttachmentDialog: React.FC<UploadAttachmentDialogProps> = ({
  eventId,
  userId,
  visible,
  onDismiss,
}) => {
  const [selectedFile, setSelectedFile] = useState<DocumentResult | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [successText, setSuccessText] = useState<string | null>(null);

  const handleUpload = async () => {
    try {
      setUploading(true);
      if (selectedFile && selectedFile.type === "success") {
        const publicUrl = await uploadEventAttachment(
          selectedFile,
          eventId,
          userId
        );
        setSuccessText(`${selectedFile.name} uploaded successfully.`);
        setError(null);
        setUploadSuccess(true);
        console.log("File uploaded:", publicUrl);
      }
    } catch (error: any) {
      setUploadSuccess(false);
      setSuccessText(null);
      if (error.error == "Duplicate" && selectedFile?.type === "success") {
        setError(
          `File ${selectedFile?.name} already exists. Select a different file or rename it.`
        );
      } else {
        setError("Something went wrong. Please try again.");
      }
      console.error("Upload failed:", error);
    } finally {
      setUploading(false);
      setSelectedFile(null);
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    onDismiss();
  };

  return (
    <Portal>
      <Dialog visible={visible} onDismiss={onDismiss}>
        <Dialog.Title>Upload attachment</Dialog.Title>
        {uploading ? (
          <Dialog.Content>
            <Text>Uploading...</Text>
            <Loading />
          </Dialog.Content>
        ) : (
          <>
            <Dialog.Content>
              {uploadSuccess === false && (
                <Text style={styles.errorText}>
                  {error || "Something went wrong. Please try again."}
                </Text>
              )}
              {uploadSuccess === true && (
                <Text style={styles.successText}>
                  {successText || "Upload successful."}
                </Text>
              )}
              <View style={{ marginTop: 8 }}>
                <Text>
                  {uploadSuccess && "Upload another? "}
                  Select a file less than {MAX_FILE_SIZE / (1024 * 1024)}MB to
                  upload:
                </Text>
                <FilePicker
                  setSelectedFile={setSelectedFile}
                  selectedFile={selectedFile}
                />
              </View>
            </Dialog.Content>
            <Dialog.Actions style={styles.actions}>
              <Button onPress={handleCancel}>
                {uploadSuccess === true ? "Done" : "Cancel"}{" "}
              </Button>
              <Button onPress={handleUpload} disabled={!selectedFile}>
                Upload
              </Button>
            </Dialog.Actions>
          </>
        )}
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  actions: {
    justifyContent: "flex-end",
  },
  successText: {
    color: "green",
  },
  errorText: {
    color: "red",
  },
});

export default UploadAttachmentDialog;
