import React from "react";
import { View, StyleSheet } from "react-native";
import {
  Card,
  Paragraph,
  Avatar,
  Text,
  ThemeProvider,
  Icon,
} from "react-native-paper";
import { types } from "../../constants/activityTypes";
import { ICONS } from "../../constants/icons";
import { formatDuration } from "../utils/time";
import { formatDateShortWithDayOfWeek } from "../utils/dateUtils";
import {
  formatDistance,
  metersToUserUnits,
  metersToFormattedUserUnits,
} from "../utils/distance";
import EventCardTitle from "./EventCardTitle";
import {
  calculateFormatPace,
  calculateFormatUserSpeed,
} from "../utils/calculatePace";
import { useTheme } from "react-native-paper";
import { EventData } from "../../types/event";

interface EventCardProps {
  event: EventData;
}

const EventCard: React.FC<EventCardProps> = ({
  event, // Add isCompleted prop
}) => {
  const theme = useTheme();

  const formattedDuration = formatDuration(event.total_time_planned);
  const formattedDistance = metersToFormattedUserUnits(
    event.total_distance_planned,
    1,
    event.type
  );

  return (
    <Card style={{ width: "100%" }}>
      <EventCardTitle
        title={event.title}
        activityTypeEnum={event.type}
        isCompleted={event.is_completed}
        eventDate={event.event_date}
      />
      <Card.Content>
        {event.description && event.description.length > 0 && (
          <Paragraph numberOfLines={3}>{event.description}</Paragraph>
        )}
        <View style={styles.detailsContainer}>
          <View style={styles.durationContainer}>
            <Icon source={ICONS.EVENT_DATE} size={24} />
            <Text variant="bodyLarge" style={styles.metricText}>
              {formatDateShortWithDayOfWeek(event.event_date)}
            </Text>
          </View>
          {event.event_checklists && (
            <View style={styles.durationContainer}>
              <Icon
                source={ICONS.EVENT_CHECKLIST}
                color={theme.colors.onSurface}
                size={24}
                style={styles.icon}
              />
              <Text variant="bodyLarge" style={styles.metricText}>
                0/3
              </Text>
            </View>
          )}
        </View>
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 16,
  },
  detailsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 8,
  },
  durationContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: 4,
    opacity: 0.8,
  },
  metricText: {
    opacity: 0.8,
  },
  type: {
    fontSize: 12,
  },
});

export default EventCard;
