import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
// import View from "../components/ThemedView";
import { Card, DataTable, Text } from "react-native-paper";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { types } from "../constants/activityTypes";
import { formatDuration, formatDate } from "../components/utils/time";
import ScreenWrapper from "../components/ScreenWrapper";
import ActivityCardTitle from "../components/Activity/ActivityCardTitle";
import { getActivityStatusColor } from "../components/utils/activityStatusColor";
import {
  calculateFormatPace,
  calculateFormatUserSpeed,
} from "../components/utils/calculatePace";
import { fetchActivityRecordingByWorkoutId } from "../api/activityRecordings";
import { Tables } from "../types/supabase";
import {
  formatDistance,
  metersToUserUnits,
  metersToFormattedUserUnits,
} from "../components/utils/distance";
import { speedOrPaceLabel } from "../components/utils/calculatePace";
import ActivityDetailsList from "../components/Activity/ActivityDetailsList";
import { useSchedule } from "../provider/ScheduleProvider";
import { findWorkoutById } from "../provider/ScheduleProvider/selectors";
import { ScheduledWorkout } from "../types/schedule";
import Loading from "./utils/Loading";

type ActivityDetailsProps = NativeStackScreenProps<
  MainStackParamList,
  "Activity"
>;

const ActivityDetails: React.FC<ActivityDetailsProps> = ({
  route,
  navigation,
}) => {
  const { activity } = route.params as { activity: ScheduledWorkout };
  const [updatedActivity, setUpdatedActivity] =
    useState<Tables<"activities">>(activity);
  const [activityRecordings, setActivityRecordings] = useState<
    Tables<"activity_recordings">[]
  >([]);
  const { state } = useSchedule();
  const [activityStatusColor, setActivityStatusColor] = useState<string>(
    getActivityStatusColor(activity.is_completed, activity.started_at)
  );
  const [formattedStartedAt, setFormattedStartedAt] = useState<string>(
    formatDate(activity.started_at_local, activity.is_completed)
  );

  useEffect(() => {
    if (!state.isLoading && updatedActivity) {
      setActivityStatusColor(
        getActivityStatusColor(
          updatedActivity.is_completed,
          updatedActivity.started_at
        )
      );
      setFormattedStartedAt(
        formatDate(
          updatedActivity.started_at_local,
          updatedActivity.is_completed
        )
      );
    }
  }, [updatedActivity]);

  async function fetchUpdatedActivityRecordings(activityId: string) {
    try {
      const { data } = await fetchActivityRecordingByWorkoutId(activityId);
      setActivityRecordings(data);
    } catch (error) {
      console.error("Error fetching activity recordings:", error);
    }
  }

  useEffect(() => {
    if (!state.isLoading) {
      fetchUpdatedActivityRecordings(activity.id);
      const activityFromState = findWorkoutById(state.schedule, activity.id);
      if (activityFromState) {
        setUpdatedActivity(activityFromState);
      }
    }
  }, [state.schedule]);

  if (!updatedActivity || state.isLoading) {
    return <Loading />;
  }

  return (
    <ScreenWrapper>
      <View style={styles.container}>
        <ActivityCardTitle
          title={updatedActivity.title}
          activityTypeEnum={updatedActivity.type}
          isCompleted={updatedActivity.is_completed}
          startedAt={updatedActivity.started_at}
          titleVariant="titleLarge"
        />
        <Text
          style={[
            styles.startedAt,
            { color: activityStatusColor }, // Conditionally set text color
          ]}
        >
          {updatedActivity.is_completed ? "Completed" : "Planned"}{" "}
          {formattedStartedAt}
        </Text>
        {updatedActivity.description && (
          <View>
            <Text variant="labelMedium" style={styles.descriptionTitle}>
              Description
            </Text>
            <Text style={styles.description}>
              {updatedActivity.description}
            </Text>
          </View>
        )}
        <Text variant="labelMedium" style={styles.descriptionTitle}>
          Summary
        </Text>
        <Card style={styles.tableContainer}>
          <DataTable>
            <DataTable.Header>
              <DataTable.Title style={styles.centeredCell}>
                Planned
              </DataTable.Title>
              <DataTable.Title style={styles.centeredCell}></DataTable.Title>
              <DataTable.Title style={styles.centeredCell}>
                Completed
              </DataTable.Title>
            </DataTable.Header>

            <DataTable.Row>
              <DataTable.Cell style={styles.centeredCell} numeric>
                {updatedActivity.total_time_planned !== null
                  ? formatDuration(updatedActivity.total_time_planned)
                  : "-"}
              </DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell}>
                Duration
              </DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell} numeric>
                {updatedActivity.total_time !== null
                  ? formatDuration(updatedActivity.total_time)
                  : "-"}
              </DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row>
              <DataTable.Cell style={styles.centeredCell} numeric>
                {metersToFormattedUserUnits(
                  updatedActivity.total_distance_planned,
                  1,
                  updatedActivity.type
                )}
              </DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell}>
                Distance
              </DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell} numeric>
                {metersToFormattedUserUnits(
                  updatedActivity.total_distance,
                  1,
                  updatedActivity.type
                )}
              </DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row>
              <DataTable.Cell style={styles.centeredCell} numeric>
                {calculateFormatUserSpeed(
                  updatedActivity.total_distance_planned,
                  updatedActivity.total_time_planned,
                  updatedActivity.type
                )}
              </DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell}>
                {speedOrPaceLabel(updatedActivity.type)}
              </DataTable.Cell>
              <DataTable.Cell style={styles.centeredCell} numeric>
                {calculateFormatUserSpeed(
                  updatedActivity.total_distance,
                  updatedActivity.total_time,
                  updatedActivity.type
                )}
              </DataTable.Cell>
            </DataTable.Row>
          </DataTable>
        </Card>
        <ActivityDetailsList activityRecordings={activityRecordings} />
      </View>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  tableContainer: {
    marginTop: 8,
    marginBottom: 8,
  },
  container: {
    // flex: 1,
    padding: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    marginBottom: 8,
  },
  descriptionTitle: {
    // fontSize: 16,
    marginBottom: 8,
  },
  type: {
    fontSize: 16,
    marginBottom: 8,
  },
  time: {
    fontSize: 16,
    marginBottom: 8,
  },
  startedAt: {
    fontSize: 16,
    marginBottom: 8,
  },
  completed: {
    fontSize: 16,
    marginBottom: 8,
  },
  centeredCell: {
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    marginBottom: 8,
    marginTop: 16,
  },
});

export default ActivityDetails;
