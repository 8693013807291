import React, { useEffect, useState } from "react";
import { List, Avatar, Divider } from "react-native-paper";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { MainStackParamList, ROUTES } from "../types/navigation";
import ScreenWrapper from "../components/ScreenWrapper";
import { supabase } from "../initSupabase";
import { Linking } from "react-native";
import { SUPPORT_URL } from "../constants/urls";
import {
  getUserInitials,
  getUserName,
} from "../components/utils/profileService";
import { isSuperuser } from "../api/users";
import { useProfile } from "../provider/ProfileProvider";

type MoreMenuScreenProps = NativeStackScreenProps<
  MainStackParamList,
  "MainTabs"
>;

const MoreMenuScreen: React.FC<MoreMenuScreenProps> = ({ navigation }) => {
  const handleMenuItemPress = (screenName: keyof MainStackParamList) => {
    navigation.navigate(screenName);
  };
  const { state } = useProfile();
  const [userEmail, setUserEmail] = useState<string>("");

  const [userIsSuperUser, setUserIsSuperUser] = useState<boolean>(false);

  useEffect(() => {
    // Fetch user data from Supabase
    const fetchUserData = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (data.session.user.email) {
        setUserEmail(data.session.user.email);
      } else {
        setUserEmail("No email found");
      }
      // Check if super user for additional menu items
      const isSuper = await isSuperuser();
      setUserIsSuperUser(isSuper);
    };

    fetchUserData();
  }, []);

  const openSupportURL = async () => {
    try {
      await Linking.openURL(SUPPORT_URL);
    } catch (error) {
      console.error("Error opening URL:", error);
    }
  };

  return (
    <ScreenWrapper>
      <List.Section>
        <List.Item
          title={getUserName(state.profile)}
          description={userEmail}
          left={() => (
            <Avatar.Text
              label={getUserInitials(state.profile)}
              size={40}
              style={{ marginLeft: 16 }}
            />
          )}
          onPress={() => handleMenuItemPress("Account Settings")}
        />
      </List.Section>
      <Divider />
      <List.Section>
        <List.Item
          title="Settings"
          onPress={() => handleMenuItemPress("Settings")}
        />
        <List.Item
          title="Connected Apps"
          onPress={() => handleMenuItemPress("Connected Apps")}
        />
        <List.Item
          title="Workout Library"
          onPress={() => handleMenuItemPress("Workout Library")}
        />
        {state.profile?.account_type === "coach" && (
          <List.Item
            title="My Athletes"
            onPress={() => handleMenuItemPress("My Athletes")}
          />
        )}
        <List.Item
          title="My Coaches"
          onPress={() => handleMenuItemPress("My Coaches")}
        />
      </List.Section>
      <Divider />
      <List.Section>
        <List.Subheader>Help and Support</List.Subheader>
        <List.Item title="Feedback" onPress={openSupportURL} />
        <List.Item title="Help" onPress={openSupportURL} />
        {userIsSuperUser && (
          <List.Item
            title="Admin Tools"
            onPress={() => handleMenuItemPress(ROUTES.ADMIN_TOOLS)}
            left={(props) => <List.Icon {...props} icon="account-cog" />}
          />
        )}
      </List.Section>
      <Divider />
      <List.Item
        title="Logout"
        onPress={async () => {
          const { error } = await supabase.auth.signOut();
          if (!error) {
            alert("Signed out!");
          }
          if (error) {
            alert(error.message);
          }
        }}
      />
    </ScreenWrapper>
  );
};

export default MoreMenuScreen;
