import { Database } from "./supabase";
// import { types as activityTypes } from "../constants/activityTypes";

export enum DisplayDistanceUnit {
  LONG = "long",
  SHORT = "short",
}

export enum DisplaySpeedUnits {
  MINUTES_PER_DISTANCE = "minutes_per_distance",
  SWIM_PACE = "swim_pace",
  DISTANCE_PER_HOUR = "distance_per_hour",
}

export enum ActivityIcon {
  RUN = "run",
  SWIM = "swim",
  RIDE = "bike",
  STRENGTH = "weight-lifter",
  WORKOUT = "heart",
  WALK = "walk",
  ELLIPTICAL = "ski-cross-country",
  DEEP_WATER_RUN = "pool",
  YOGA = "yoga",
  RECOVERY = "heart",
  MULTISPORT = "dots-horizontal-circle-outline",
  OTHER = "heart",
}

export enum ActivityTypeValue {
  RUN = 1,
  SWIM = 2,
  RIDE = 3,
  STRENGTH = 4,
  WORKOUT = 5,
  WALK = 6,
  ELLIPTICAL = 7,
  DEEP_WATER_RUN = 8,
  YOGA = 9,
  RECOVERY = 10,
  MULTISPORT = 11,
  OTHER = 12,
}

export enum ActivityTypeLabel {
  RUN = "Run",
  SWIM = "Swim",
  RIDE = "Ride",
  STRENGTH = "Strength",
  WORKOUT = "Workout",
  WALK = "Walk",
  ELLIPTICAL = "Elliptical",
  DEEP_WATER_RUN = "Deep Water Run",
  YOGA = "Yoga",
  RECOVERY = "Recovery",
  MULTISPORT = "Multisport",
  OTHER = "Other",
}

export interface BaseActivityType {
  label: ActivityTypeLabel;
  value: ActivityTypeValue;
  icon: ActivityIcon;
  displayDistanceUnit: DisplayDistanceUnit;
  displaySpeedUnits: DisplaySpeedUnits;
}

export const RunActivityType: BaseActivityType = {
  label: ActivityTypeLabel.RUN,
  value: ActivityTypeValue.RUN,
  icon: ActivityIcon.RUN,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const SwimActivityType: BaseActivityType = {
  label: ActivityTypeLabel.SWIM,
  value: ActivityTypeValue.SWIM,
  icon: ActivityIcon.SWIM,
  displayDistanceUnit: DisplayDistanceUnit.SHORT,
  displaySpeedUnits: DisplaySpeedUnits.SWIM_PACE,
};

export const RideActivityType: BaseActivityType = {
  label: ActivityTypeLabel.RIDE,
  value: ActivityTypeValue.RIDE,
  icon: ActivityIcon.RIDE,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.DISTANCE_PER_HOUR,
};

export const StrengthActivityType: BaseActivityType = {
  label: ActivityTypeLabel.STRENGTH,
  value: ActivityTypeValue.STRENGTH,
  icon: ActivityIcon.STRENGTH,
  displayDistanceUnit: DisplayDistanceUnit.SHORT,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const WorkoutActivityType: BaseActivityType = {
  label: ActivityTypeLabel.WORKOUT,
  value: ActivityTypeValue.WORKOUT,
  icon: ActivityIcon.WORKOUT,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const WalkActivityType: BaseActivityType = {
  label: ActivityTypeLabel.WALK,
  value: ActivityTypeValue.WALK,
  icon: ActivityIcon.WALK,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const EllipticalActivityType: BaseActivityType = {
  label: ActivityTypeLabel.ELLIPTICAL,
  value: ActivityTypeValue.ELLIPTICAL,
  icon: ActivityIcon.ELLIPTICAL,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const DeepWaterRunActivityType: BaseActivityType = {
  label: ActivityTypeLabel.DEEP_WATER_RUN,
  value: ActivityTypeValue.DEEP_WATER_RUN,
  icon: ActivityIcon.DEEP_WATER_RUN,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const YogaActivityType: BaseActivityType = {
  label: ActivityTypeLabel.YOGA,
  value: ActivityTypeValue.YOGA,
  icon: ActivityIcon.YOGA,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const RecoveryActivityType: BaseActivityType = {
  label: ActivityTypeLabel.RECOVERY,
  value: ActivityTypeValue.RECOVERY,
  icon: ActivityIcon.RECOVERY,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

export const MultisportActivityType: BaseActivityType = {
  label: ActivityTypeLabel.MULTISPORT,
  value: ActivityTypeValue.MULTISPORT,
  icon: ActivityIcon.MULTISPORT,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.DISTANCE_PER_HOUR,
};

export const OtherActivityType: BaseActivityType = {
  label: ActivityTypeLabel.OTHER,
  value: ActivityTypeValue.OTHER,
  icon: ActivityIcon.OTHER,
  displayDistanceUnit: DisplayDistanceUnit.LONG,
  displaySpeedUnits: DisplaySpeedUnits.MINUTES_PER_DISTANCE,
};

// Define a union type for all specific activity types
export type ActivityType =
  | typeof RunActivityType
  | typeof SwimActivityType
  | typeof RideActivityType
  | typeof StrengthActivityType
  | typeof WorkoutActivityType
  | typeof WalkActivityType
  | typeof EllipticalActivityType
  | typeof DeepWaterRunActivityType
  | typeof YogaActivityType
  | typeof RecoveryActivityType
  | typeof MultisportActivityType
  | typeof OtherActivityType;

type ActivityDataBase = Database["public"]["Tables"]["activities"]["Row"];
// Define the properties to be made optional in the front end
type OptionalActivityDataFields = {
  created_at?: string | null;
  deleted_at?: string | null;
  id?: string;
  updated_at?: string | null;
};
// Combine the base type omitting the optional fields, then union with the optional fields
export type ActivityData = Omit<
  ActivityDataBase,
  keyof OptionalActivityDataFields
> &
  OptionalActivityDataFields & {
    type: ActivityTypeValue;
  };

export type ActivityRecordingData =
  Database["public"]["Tables"]["activity_recordings"]["Row"];

export type ActivityTotals = {
  total_count: number;
  total_count_planned: number;
  total_count_missed: number;
  total_count_planned_incomplete: number;
  total_time: number;
  total_time_planned: number;
  total_time_missed: number;
  total_time_planned_incomplete: number;
  total_distance: number;
  total_distance_planned: number;
  total_distance_missed: number;
  total_distance_planned_incomplete: number;
  total_distance_completed?: number;
  type?: ActivityTypeValue;
  date?: string;
  daily?: Record<string, any>;
  weekly?: Record<string, any>;
};

export type ActivityTotalsBase = {
  total_count: number;
  total_time: number;
  total_distance: number;
  total_count_planned: number;
  total_time_planned: number;
  total_distance_planned: number;
  total_count_missed: number;
  total_time_missed: number;
  total_distance_missed: number;
  total_count_planned_incomplete: number;
  total_time_planned_incomplete: number;
  total_distance_planned_incomplete: number;
  total_distance_completed?: number;
  type?: ActivityTypeValue;
  date?: string;
};

export type ActivityTotalsCollection = {
  [date: string]: ActivityTotalsBase;
};

export interface WeeklyActivityTotalsCollection extends ActivityTotals {
  weekly: ActivityTotalsCollection;
}

export interface DailyActivityTotalsCollection extends ActivityTotals {
  daily: ActivityTotalsCollection;
}
export interface WeeklyActivityTotalsPerActivityCollection {
  [key: string]: WeeklyActivityTotalsCollection;
}

export interface DailyActivityTotalsPerActivityCollection {
  [key: string]: DailyActivityTotalsCollection;
}

export type ActivityTotalsPerActivity = {
  [activityType in ActivityTypeLabel | "total"]: ActivityTotals;
};

export type ActivityTotalsPerDay = {
  [date: string]: ActivityTotals;
};

export type ActivityTotalsPerWeek = {
  [date: string]: ActivityTotalsPerWeek;
};

export type ActivityStatus = {
  label: string;
  color: string;
  totalKey: TotalType;
};

export interface TotalTypeMap {
  duration: string;
  distance: string;
  count: string;
}

export enum TotalType {
  DURATION = "duration",
  DISTANCE = "distance",
  COUNT = "count",
}
