import React, { useState } from "react";
import { View, StyleSheet, Alert } from "react-native";
import { Card, Icon, IconButton, Menu } from "react-native-paper";
import { FileObject } from "@supabase/storage-js";
import { ICONS } from "../../constants/icons";
import {
  downloadEventAttachment,
  deleteEventAttachment,
  renameEventAttachment,
} from "../../api/events";
import { EventData } from "../../types/event";
import DeleteConfirmation from "../utils/DeleteConfirmation";
import RenameFileDialog from "./RenameAttachmentDialog";
import { openDownloadedFile } from "../utils/fileIO";

interface AttachmentCardProps {
  file: FileObject;
  eventId: EventData["id"];
  userId: EventData["user_id"];
  setFetchAttachmentList: React.Dispatch<React.SetStateAction<boolean>>;
}

const AttachmentCard: React.FC<AttachmentCardProps> = ({
  file,
  eventId,
  userId,
  setFetchAttachmentList,
}) => {
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [showRenameDialog, setShowRenameDialog] = useState<boolean>(false);

  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);

  const handlePress = async () => {
    console.log("Open file:", file);
    try {
      const data = await downloadEventAttachment(eventId, userId, file.name);
      console.log("Downloaded file:", data);
      await openDownloadedFile(data, file.name);
    } catch (error) {
      console.error("Error downloading and opening attachment:", error);
    }
  };

  const handleRename = () => {
    closeMenu();
    console.log("Rename file:", file);
    setShowRenameDialog(true);
  };

  const handleSetAsCover = () => {
    closeMenu();
    console.log("Set as cover image:", file);
  };

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmRename = async (newFileName: string) => {
    try {
      await renameEventAttachment(eventId, userId, file.name, newFileName);
      setFetchAttachmentList(true);
      closeMenu();
      console.log("Rename file:", file, "to", newFileName);
    } catch (error) {
      console.error("Error renaming attachment:", error);
      Alert.alert("Error", "Failed to rename attachment");
    } finally {
      setShowRenameDialog(false);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteEventAttachment(eventId, userId, file.name);
      setFetchAttachmentList(true);
      closeMenu();
      console.log("Delete file:", file);
    } catch (error) {
      console.error("Error deleting attachment:", error);
      Alert.alert("Error", "Failed to delete attachment");
    } finally {
      setShowDeleteConfirmation(false);
    }
  };

  return (
    <Card style={styles.card} onPress={handlePress}>
      <Card.Title
        title={file.name}
        left={(props) => <Icon {...props} source="file" />}
        right={(props) => (
          <Menu
            visible={menuVisible}
            onDismiss={closeMenu}
            anchor={
              <IconButton {...props} icon={ICONS.MORE} onPress={openMenu} />
            }
          >
            <Menu.Item
              onPress={handleRename}
              title="Rename"
              leadingIcon={ICONS.EDIT}
            />
            <Menu.Item
              onPress={handleSetAsCover}
              title="Set as cover image"
              leadingIcon={ICONS.CHECKBOX_UNMARKED}
            />
            <Menu.Item
              onPress={handleDelete}
              title="Delete"
              leadingIcon={ICONS.DELETE}
            />
          </Menu>
        )}
      />
      {showRenameDialog && (
        <RenameFileDialog
          visible={showRenameDialog}
          onDismiss={() => setShowRenameDialog(false)}
          onRename={handleConfirmRename}
          initialFileName={file.name}
        />
      )}
      {showDeleteConfirmation && (
        <DeleteConfirmation
          message={`Are you sure you want to delete ${file.name}? This can't be undone.`}
          onConfirm={handleConfirmDelete}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}
    </Card>
  );
};

const styles = {
  card: {
    marginBottom: 10,
  },
};

export default AttachmentCard;
